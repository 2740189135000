import React, { useEffect } from "react";
import { getUser } from "../Storage";
import { useSelector } from "react-redux";

const CardTable = () => {

    const WelcomePageDetailsReducer = useSelector(state => state.WelcomePageDetailsReducer)

    // useEffect(() => {
        
    //     // console.log('WelcomePageDetailsReducer', WelcomePageDetailsReducer);
    // }, [WelcomePageDetailsReducer.doc])

    var height = window.innerHeight

    let noDataFound;
    var table = []

    function checkIfNoDataFound(data) {
        data.map((ele)=>{
            if(ele.name!==''){ table.push(ele) }
        })
    }

    if (noDataFound) {
        console.log("No data found");
    } else {
        console.log("Data found");
    }

    // console.log('WelcomePageDetailsReducer',WelcomePageDetailsReducer);

    return (<>
        {WelcomePageDetailsReducer.doc !== null ?

            <div className="card rounded-3xl p-7 pb-12 shadow-2xl" style={{ background:WelcomePageDetailsReducer.doc.status==='Completed'?'#EAF5EA':WelcomePageDetailsReducer.doc.status==='Yet to start'?'#FCF0E4':'#E6EBF1',height: 'max-content', minHeight: height - 110 }}>
                <header>
                    <h4 className="text-black pb-5" style={{ fontSize:'25px',borderBottom: '1px solid #9e191a' }}>{WelcomePageDetailsReducer.doc.title}</h4>
                    {
                        WelcomePageDetailsReducer.doc.topic ?
                            <div style={{ borderBottom: '1px solid #9e191a' }} className="w-full flex pb-5 pt-5">
                                <p className="font-bold text-xs w-11">Topic :</p>
                                <p className="font-bold text-xs">{WelcomePageDetailsReducer.doc.topic}</p>
                            </div>
                            : ''
                    }
                </header>

                {
                    WelcomePageDetailsReducer.doc.table === true ?
                        <div className="pb-5 pt-5" style={{ borderBottom: '1px solid #9e191a' }}>
                            {noDataFound = checkIfNoDataFound(WelcomePageDetailsReducer.doc.membersInThisGroup)}
                            <h4 className="text-sm w-max font-bold pb-5">Members in this group</h4>
                            <div className="bg-[#C3D8EA] px-2 py-1 rounded-2xl grid grid-cols-10 gap-4 text-sm">
                                <div className="col-span-1">S.No.</div>
                                <div className="col-span-2">Name</div>
                                <div className="col-span-4">Role</div>
                                <div className="col-span-3">Organisation</div>
                            </div>
                            {
                                table.length > 0 ?
                                    table.map((ele, i) => {
                                        return (
                                            <div className="p-1 rounded-2xl grid grid-cols-10 gap-4 text-sm mt-3 ">
                                                <div className="col-span-1 bg-orange-300 text-center pt-1 w-7 h-7 rounded-full">{i + 1}</div>
                                                <div className="col-span-2">{ele.name}</div>
                                                <div className="col-span-4">{ele.role}</div>
                                                <div className="col-span-3">{ele.organisation}</div>
                                            </div>
                                        )
                                    })
                                    : <p className="mt-1 text-sm">No Data Found</p>
                            }
                        </div>
                        :
                        <div style={{ borderBottom: '1px solid #9e191a' }}>
                            <p className="text-sm mt-5 mb-5"><b>Leader Name :</b> <br />{WelcomePageDetailsReducer.doc.LeaderName!==''?WelcomePageDetailsReducer.doc.LeaderName:'Yet to be assigned'}</p>
                            <p className="text-sm mb-5"><b>Leader Details : </b><br />{WelcomePageDetailsReducer.doc.LeaderDetails!==''?WelcomePageDetailsReducer.doc.LeaderDetails:'Yet to be assigned'}</p>
                            {/* <p className="text-sm mb-5"><b>Overall Status : </b><br/>{WelcomePageDetailsReducer.doc.OverallStatus}</p> */}
                        </div>
                }



                <div className="mt-10">
                    {/* <!-- component --> */}
                    <div className="mx-4 p-4 pt-10">
                        <div className="flex items-center">
                            {
                                WelcomePageDetailsReducer.doc.footer.map((ele, i) => {

                                    // console.log('ele',ele);

                                    return (<>
                                        <div className="flex items-center text-orange-300 relative">
                                            <div className="rounded-full transition duration-500 ease-in-out h-8 w-8 pt-0.5 border-2 bg-[#F2B9C6] text-black border-[#F2B9C6]">
                                                <p className="text-center">0{i + 1}</p>
                                            </div>
                                            <div className="absolute top-0 -ml-11 text-center -mt-14 w-32 text-xs font-medium uppercase text-black">
                                                <div>
                                                    <div className="normal-case">{ele.date}</div>
                                                    <center><div className={`${ele.date ? 'mt-2' : 'mt-6'} text-[8px] font-bold ${ele.status === 'Complete' || ele.status === 'Done' ? 'w-6 h-6 bg-[#CAE0CA]' : ele.status === 'Yet to start' || ele.status === 'LC Yet to start' ? 'w-max pl-2 pr-2 pb-0.5 bg-[#EAC9BA]' : ele.status === 'Initiated' || ele.status === 'Scheduled' ? 'w-max pl-2 pr-2 pb-0.5 bg-[#C3D8EA]' : ''} rounded-full -ml-1.5 text-black pt-1`}>{ele.status === 'Complete' || ele.status === 'Done' ? '✔' : ele.status}</div></center>
                                                </div>
                                            </div>
                                            <div className="absolute top-0 text-center -ml-6 mt-10 w-20 font-bold uppercase text-black" style={{ fontSize: '9px' }}><span>{ele.title}</span></div>
                                        </div>
                                        {WelcomePageDetailsReducer.doc.footer.length !== i + 1 ? <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-orange-300"></div> : ''}
                                    </>)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            :
            ''}
    </>)
}

export default CardTable;


// EAF5EA