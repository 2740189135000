import React, { useEffect } from "react";
import CardTable from "../Component/CardTable";
import { useSelector } from "react-redux";
import StatusCard from "./StatusCard";
import { getUser } from "../Storage";
import WelcomeScreen from "./WelcomeScreen";
import bg1 from '../Image/Background-a-Images (3).png';
import bg2 from '../Image/01-ABG Leading Edge-30 (1).png';
import bg3 from '../Image/01-ABG Leading Edge-29 (1).png';

const WelcomeMainPage = () => {

    const WelcomePageDetailsReducer = useSelector(state => state.WelcomePageDetailsReducer)

    var localUser = getUser()

    // console.log('WelcomePageDetailsReducer.doc.title',WelcomePageDetailsReducer.doc);

    return (<>

        <section className="p-3 w-[48%]">
            <header>
                <h6 className="pb-3 mt-5 default-red-color text-2xl ml-10 font-bold">Welcome {localUser.Participant_Name}</h6>
            </header>
            <div className="mt-3 ml-5">
                {
                    WelcomePageDetailsReducer.doc !== null ?
                        WelcomePageDetailsReducer.doc.status === 'Completed'  && WelcomePageDetailsReducer.doc.title !== 'Knowledge Integration Program (KIP)' &&
                        WelcomePageDetailsReducer.doc.title !== 'Know Your Talent (KYT)' && WelcomePageDetailsReducer.doc.title !== 'Shadow' && WelcomePageDetailsReducer.doc.title !== 'Leadership Circle (LC)'?
                            <WelcomeScreen bg={bg2} title={WelcomePageDetailsReducer.doc.title} status={WelcomePageDetailsReducer.doc.status}/>
                            :
                            WelcomePageDetailsReducer.doc.status === 'Yet to start' && WelcomePageDetailsReducer.doc.title !== 'Knowledge Integration Program (KIP)' &&
                            WelcomePageDetailsReducer.doc.title !== 'Know Your Talent (KYT)' && WelcomePageDetailsReducer.doc.title !== 'Shadow' && WelcomePageDetailsReducer.doc.title !== 'Leadership Circle (LC)' ?
                                <WelcomeScreen bg={bg3} title={WelcomePageDetailsReducer.doc.title} status={WelcomePageDetailsReducer.doc.status}/>
                                :
                                <CardTable />
                        :
                        <WelcomeScreen bg={bg1} />
                }

            </div>
        </section>

    </>)
}

export default WelcomeMainPage;