import React from "react";

const ClientBottomCard = (props) => {
    return (<>
        <div className="card shadow-md rounded-lg p-3">
            <div className="grid grid-cols-3">
                <div className="col-span-2">
                    <p className={`${props.titleC} text-sm ${props.title==='Designed and managed by'?'w-40':''}`}>{props.title}</p>
                </div>
                <div className="ml-auto">
                    <img className={`${props.title==='Designed and managed by'?'pt-3':''}`} width={props.logoW} height={props.logoH} src={props.logo} />
                </div>
            </div>
        </div>
    </>)
}

export default ClientBottomCard;