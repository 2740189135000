import React, { useState } from "react";
import { BulkUploadApi } from "./UploadApi";
import toast from "react-hot-toast";

const UploadMain = () => {

    const [file,setFile] = useState(null)
    const [loader,setLoader] = useState(false)
    

    const upload = () =>{

        if(file === null){toast.error('Please select file')}
        else if(!file[0].type === 'text/csv'){toast.error('Please upload CSV file only')}
        else{
            var json = {
                csvFile : file[0],
            }
            setLoader(true)
            BulkUploadApi(json,setLoader)
        }
    }

    return (<>
        <div className="grid h-screen place-items-center">
            <div className="w-96 shadow-lg">
                <div className="bg-[#9E191A] text-white p-2">
                    Select And Upload Your File
                </div>
                <div className="p-5">
                    <input onChange={(e)=>setFile(e.target.files)} className="border rounded-md w-full" type="file" />
                </div>
                <div className="pl-5 pb-5">
                    <button onClick={()=>upload()} type="button" class="text-white bg-[#9E191A] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ">{loader?'loading...':'Upload'}</button>
                </div>
            </div>
        </div>
    </>)
}

export default UploadMain;