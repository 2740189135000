import React, { useState } from "react";
import MyLeadingEdgeJourneyOverview from "./MyLeadingEdgeJourneyOverview/MyLeadingEdgeJourneyOverview";
import WelcomeMainPage from "./WelcomeMainPage/WelcomeMainPage";
import ProfileSection from "./ProfileSection/ProfileSection";
import { getUser } from "./Storage";
import { getSearchUserApi } from "./Store/Action/Login";

const Dashboard = () => {

    const [data, setData] = useState(true)

    var width = window.innerWidth
    var height = window.innerHeight

    var localUser = getUser()

    if (data === true) {
        var json = {
            _id:localUser._id
        }
        // console.log('call');
        getSearchUserApi(json,setData)
    }

    return (<>

        <div>
            <div className="flex">
                <MyLeadingEdgeJourneyOverview />
                <WelcomeMainPage />
                <ProfileSection />
            </div>

            <div className="bg-[#9e191a] text-white text-center p-1">
                <p>Disclaimer : This is a static website. The data is constantly being updated. Kindly expect a day's lag in the latest status. In case the latest status is not reflecting, please reach out to us.</p>
            </div>
        </div>

        {/* <div className="flex">
            <div style={{ width: width / 3, height: height }}>
                <MyLeadingEdgeJourneyOverview />
            </div>

            <div style={{ width: width / 2, height: height }}>
                <WelcomeMainPage />
            </div>

            <div>
                <ProfileSection />
            </div>
        </div> */}

        {/* <div className="grid grid-cols-4 gap-0 h-screen">
            <div className="col-span-1">
                <MyLeadingEdgeJourneyOverview />
            </div>
            <div className="col-span-2">
                <WelcomeMainPage />
            </div>
            <div className="col-span-1">
                <ProfileSection />
            </div>
        </div> */}

    </>)
}

export default Dashboard;