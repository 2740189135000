import React from "react";
import Dashboard from "./Dashboard";
import Upload from "./Upload/Upload";
import { Route, Routes } from "react-router-dom";

const Panel = () => {
    return (<>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/upload" element={<Upload />} />
            </Routes>
    </>)
}

export default Panel;