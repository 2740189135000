import axios from "axios";
import { BulkUpload } from "../Constants/Constants";
import toast from "react-hot-toast";

export const BulkUploadApi = (json,setLoader) => {

    axios.post(BulkUpload, json, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then((res) => {
        // console.log('res-----',res);
        if(res.data.status === 200){
            toast.success(res.data.message)
            setLoader(false)
        }
    })
        .catch((err) => {
            toast.error("somthing went wrong")
            setLoader(false)
        });

}

