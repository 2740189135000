import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { getUser } from "../Storage";

const OverallProgress = () => {

    var user = getUser()

    let OverallProgress = false;
    let PeerProgress  = false;
    
    if(user.Overall_Progress!==''){
        OverallProgress = user.Overall_Progress.split('%')[0]
    }
    if(user.Peer_Progress!==''){
        PeerProgress = user.Peer_Progress.split('%')[0]
    }

    return (<>
        <div className="card shadow-md rounded-lg p-3">
            <header className="flex justify-between">
                <div className="font-bold">Overall Progress</div>
            </header>
            <div className="grid grid-cols-4 mt-5">
                <div className="w-16 h-2w-16 col-span-1">
                    <CircularProgressbar value={OverallProgress?Number(OverallProgress):0} text={`${OverallProgress?Number(OverallProgress).toFixed()+'%':'0%'}`} styles={buildStyles({
                        pathColor: "#81B9E6",
                    })} />
                </div>
                <div className="col-span-3 mt-1 ml-5">
                    <div className="text-sm">I have covered</div>
                    <div className="text-xl font-bold">{OverallProgress?Number(OverallProgress).toFixed()+'%':'0%'}</div>
                </div>
            </div>
            <div className="mt-5 border border-b-[#f2b9c6]"></div>
            <div className="grid grid-cols-4 mt-5">
                <div className="w-16 h-2w-16 col-span-1">
                    <CircularProgressbar value={PeerProgress?Number(PeerProgress):0} text={`${PeerProgress?Number(PeerProgress).toFixed()+'%':'0%'}`} styles={buildStyles({
                        pathColor: "#ADE2AD",
                    })} />
                </div>
                <div className="col-span-3 mt-1 ml-5 ">
                    <div className="text-sm">Most of my peers in this this track have coverd</div>
                    <div className="text-xl font-bold">{PeerProgress?Number(PeerProgress).toFixed()+'%':'0%'}</div>
                </div>
            </div>
        </div>
    </>)
}

export default OverallProgress;