import React from "react";
import { getUser } from "../Storage";
import Clender1 from '../Image/Untitled-2-02.png';
import Clender2 from '../Image/Untitled-2-03.png';
import Clender3 from '../Image/Untitled-2-04.png';
import Clender4 from '../Image/Untitled-2-02-05 (2).png';
import GoToLink from '../Image/Logo.png';

const UpcomingTask = () => {

    var getLocalUser = getUser()

    return (<>
        <div className="card shadow-md rounded-lg p-3">
            <header className="flex justify-between">
                <div className="font-bold text-base">Upcoming Tasks</div>
            </header>
            <div className="flex mt-5">
                <div className="text-white">
                    <img className="w-9 h-9" src={Clender4} />
                </div>
                <div className="ml-5">
                    <div className="text-sm font-bold mt-2">{getLocalUser.Next_Task_KIP}</div>
                    {/* <div className="text-xs">{getLocalUser.Next_Task_Date_KIP}</div> */}
                </div>
            </div>
            <div className="flex mt-5">
                <div className=" text-white">
                    <img className="w-9 h-9" src={Clender4} />
                </div>
                <div className="ml-5">
                    <div className="text-sm font-bold mt-2">{getLocalUser.Next_Task_KYT}</div>
                    {/* <div className="text-xs">{getLocalUser.Next_Task_Date_KYT}</div> */}
                </div>
            </div>
            <div className="flex mt-5">
                <div className=" text-white">
                    <img className="w-9 h-9" src={Clender4} />
                </div>
                <div className="ml-5">
                    <div className="text-sm font-bold mt-2">{getLocalUser.Next_Task_Shadow}</div>
                    {/* <div className="text-xs">{getLocalUser.Next_Task_Date_Shadow}</div> */}
                </div>
            </div>
            <div className="flex mt-5">
                <div className=" text-white">
                    <img className="w-9 h-9" src={Clender4} />
                </div>
                <div className="ml-5">
                    <div className="text-sm font-bold mt-2">{getLocalUser.Next_Task_LC}</div>
                    {/* <div className="text-xs">{getLocalUser.Next_Task_Date_LC}</div> */}
                </div>
            </div>

        </div>
    </>)
}

export default UpcomingTask;