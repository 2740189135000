import toast from "react-hot-toast";
import React, { useState } from 'react';
import { Login, SearchUser, decrypt } from "../../Constants/Constants";
import { getUser, setUser } from "../../Storage";



export const getLoginApi = (json, setLoader) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(json)
    };

    fetch(Login, requestOptions)
        .then(res => res.json())
        .then(
            async (result) => {
                console.log("result", typeof (result))

                if (result.status === 200 && typeof result === 'object' && result !== null && result !== undefined) {
                    setUser(result.doc[0])
                    toast.success('Welcome ' + result.doc[0].Participant_Name)

                    setTimeout(() => {
                        setLoader(false)
                        window.location.reload()
                    }, 1000);
                }
                else if (result.status == 404) {
                    // console.log("result", result);
                    toast.error(result.message)
                    setLoader(false)
                }
            },
            (error) => {

            }
        )

}


// console.log("user stored in Redux", setUser)

export const getSearchUserApi = (json, setLoader) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(json)
    };

    fetch(SearchUser, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {


                if (result.status === 200) {

                    setUser(result.doc)
                    setLoader(false)
                }
                else {
                    console.log(result);
                    toast.error(result.message)
                    setLoader(false)
                }
            },
            (error) => {

            }
        )

}

