import React, { useEffect } from "react";
import Login from "./Login";
import Panel from "./Panel";
import { getUser } from "./Storage";
import Test from "./Test";

const App = () => {

  var getUserFromLocalStroge = getUser()

  // console.log('getUserFromLocalStroge',getUserFromLocalStroge);

 
  return (<>

    {/* <Test/> */}

    {
      getUserFromLocalStroge === null ?
        <Login />
        :
        <Panel />
    }
  </>)
}

export default App