import React, { useEffect, useState } from "react";
import { getLoginApi } from "./Store/Action/Login";
import toast from "react-hot-toast";
import bgImage from "../src/Image/01-ABG-Leading-Edge-hig-res.jpg";
import { setUser } from "./Storage";
import '../src/index.css'

import Logo from "../src/Image/Logo.png";

const Login = () => {

    const [username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [Loader, setLoader] = useState(false)
    const [emailAddress, setEmailAddress] = useState('')

    const [forget, setForgget] = useState(false)


    useEffect(() => {
        console.log("called inside useEffect of login")
          localStorage.clear()
        
      }, [])
    

    const onClick = () => {
        if (username === '') { toast.error('Username is required') }
        else if (Password === '') { toast.error('Password is required') }
        else {
            var json = {
                Email_ID: username,
                Password: Password
            }
            setLoader(true)
            getLoginApi(json,setLoader)
        }
    }

    return (<>
        <div>
            <img src={bgImage}/>
            <div style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}>
            {
            forget === false ?

                <div className="shadow-lg px-24 py-10 bg-white text-gray-700">
  <center><img width='200' height='200' src={Logo} alt="Company Logo"/></center>
  <div>
    <div className="mt-5 mb-5">
      <div className="mb-0.5"><label>Username</label></div>
      <input defaultValue={username} type="text" onChange={(e) => setUsername(e.target.value)} className="input-field w-full border border-gray-200 rounded-lg p-3" placeholder="Enter username here..." />
    </div>
    <div className="mt-5 mb-5">
      <div className="mb-0.5"><label>Password</label></div>
      <input defaultValue={Password} type="password" onChange={(e) => setPassword(e.target.value)} className="input-field w-full border border-gray-200 rounded-lg p-3" placeholder="Enter password here..." />
    </div>
  </div>

<center>
  <button onClick={() => onClick()} type="button" className="mt-2 w-full text-white bg-red-600 hover:bg-red-700 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 flex justify-center items-center">
    {Loader ? (
      <div className="loader"></div> 
    ) : (
      'Log In'
    )}
  </button>
</center>

<div 
    className="text-center mt-5 text-lg cursor-pointer text-red-600 hover:text-red-800 transition-colors duration-300 ease-in-out" 
    onClick={() => setForgget(true)}
  >
    Forgot password?
  </div>
</div>



                :
                <div className="shadow-lg px-20 py-10 bg-white">
                    <center> <img width='200' height='200' src={Logo} /></center>
                    {/* <h1 className="text-center text-3xl mt-2 mb-2">Login</h1> */}
                    <div>
                        <div className="text-center mt-5 mb-8">
                            <h2 className="text-xl">Forgot password</h2>
                            <p className="text-base font-light w-max">
                                Please contact <b>leadingedge@adityabirla.com</b> from your <b>official email ID</b> (email ID that you use as the user ID).<br/>
                                This is a static website - the password will be reset by the site admin in the backend.
                            </p>
                        </div>
                    </div>
                    <div className="text-center text-[#AD2025] mt-5 font-medium text-lg cursor-pointer" onClick={() => setForgget(false)}>Back</div>
                </div>
        }
            </div>
        </div>
    </>)
}

export default Login;









// <div className="grid h-screen place-items-center login_background" style={{ background: `url(${bgImage})`, backgroundSize: '100%', backgroundRepea: 'no-repeat', }}>
        
//         {
//             forget === false ?
//                 <div className="shadow-lg px-20 py-10 bg-white">
//                     <center> <img width='200' height='200' src={Logo} /></center>
//                     {/* <h1 className="text-center text-3xl mt-2 mb-2">Login</h1> */}
//                     <div>
//                         <div className="mt-5 mb-5">
//                             <div><label>Username</label></div>
//                             <input defaultValue={username} onChange={(e) => setUsername(e.target.value)} className="w-60 border rounded-lg p-2" placeholder="Enter username here..." />
//                         </div>
//                         <div className="mt-5 mb-5">
//                             <div><label>Password</label></div>
//                             <input defaultValue={Password} type="password" onChange={(e) => setPassword(e.target.value)} className="w-60 border rounded-lg p-2" placeholder="Enter password here..." />
//                         </div>
//                     </div>
//                     <center>
//                         <button onClick={() => onClick()} type="button" className="mt-2 w-full text-white bg-[#AD2025] rounded-lg text-md px-5 py-2.5 mr-2 mb-2 ">Log In</button>
//                     </center>
//                     <div className="text-center text-[#AD2025] mt-5 font-medium text-lg cursor-pointer" onClick={() => setForgget(true)}>Forgot password</div>
//                 </div>
//                 :
//                 <div className="shadow-lg px-20 py-10 bg-white">
//                     <center> <img width='200' height='200' src={Logo} /></center>
//                     {/* <h1 className="text-center text-3xl mt-2 mb-2">Login</h1> */}
//                     <div>
//                         <div className="text-center mt-5 mb-8">
//                             <h2 className="text-lg">Forgot password</h2>
//                             <p className="text-sm font-light">Enter your email address below <br /> to receive password reset link</p>
//                         </div>
//                         <div className="mt-5 mb-8">
//                             <input onChange={(e) => setEmailAddress(e.target.value)} className="w-60 border rounded-lg p-2" placeholder="Email address" />
//                         </div>
//                     </div>
//                     <center>
//                         <button onClick={() => onForgetClick()} type="button" className="w-full text-white bg-[#AD2025] rounded-lg text-md px-5 py-2.5 mr-2 mb-2 ">Submit</button>
//                     </center>
//                     <div className="text-center text-[#AD2025] mt-5 font-medium text-lg cursor-pointer" onClick={() => setForgget(false)}>Back</div>
//                 </div>
//         }
//     </div>





  // <div className="shadow-lg px-20 py-10 bg-white">
                //     <center> <img width='200' height='200' src={Logo} /></center>
                //     {/* <h1 className="text-center text-3xl mt-2 mb-2">Login</h1> */}
                //     <div>
                //         <div className="mt-5 mb-5">
                //             <div className="mb-0.5"><label>Username</label></div>
                //             <input defaultValue={username} type="text" onChange={(e) => setUsername(e.target.value)} className="w-60 border border-gray-400 rounded-lg p-2" placeholder="Enter username here..." />
                //         </div>
                //         <div className="mt-5 mb-5">
                //             <div className="mb-0.5"><label>Password</label></div>
                //             <input defaultValue={Password} type="password" onChange={(e) => setPassword(e.target.value)} className="w-60 border border-gray-400 rounded-lg p-2" placeholder="Enter password here..." />
                //         </div>
                //     </div>
                //     <center>
                //         <button onClick={() => onClick()} type="button" className="mt-2 w-full text-white bg-[#AD2025] rounded-lg text-md px-5 py-2.5 mr-2 mb-2 ">{Loader?'Loading...':'Log In'}</button>
                //     </center>
                //     <div className="text-center text-[#AD2025] mt-5 font-medium text-lg cursor-pointer" onClick={() => setForgget(true)}>Forgot password</div>
                // </div>


  {/* <div className="text-center mt-5 font-medium text-lg cursor-pointer hover:text-red-700 transition-colors duration-300 ease-in-out" onClick={() => setForgget(true)}>Forgot password</div> */}
