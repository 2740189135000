export const getFullYear = (UNIX_timestamp) => {
    var t = parseInt(UNIX_timestamp)
    var a = new Date(t);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year;
    return time;
}


export const countDown = (setState) => {

    var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();
    var x = setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;

        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setState(seconds)

        if (seconds === 0) {

            setState(null)
        }
    }, 1000)
}
