import { Buffer } from "buffer"


// ABG Api
export const ABGbaseurl = "https://abg.headsupcorporation.com/api/"
export const ABGbaseurl2 = "https://abg.headsupcorporation.com/"

// export const ABGbaseurl = "http://192.168.0.104:3001/api/"
// export const ABGbaseurl2 = "http://192.168.0.104:3001/"

export const Login = ABGbaseurl + 'Login'
export const BulkUpload = ABGbaseurl2 + 'BulkUpload'
export const SearchUser = ABGbaseurl + 'searchUser'

const secretKey = process.env.REACT_APP_SECRET_CODE



export async function decrypt(text) {
    const textParts = text.split(':');
    const iv = Buffer.from(textParts.shift(), 'hex');

    if (iv.length !== 16) {
        // console.log(null);


    } else {
        const encryptedText = Buffer.from(textParts.join(':'), 'hex');
        const algorithm = { name: 'AES-CBC', iv: new Uint8Array(iv) };
        const keyBuffer = await crypto.subtle.importKey('raw', Buffer.from(secretKey, 'hex'), algorithm, false, ['decrypt']);
        const decryptedContent = await crypto.subtle.decrypt(algorithm, keyBuffer, encryptedText);
        return new TextDecoder().decode(decryptedContent);
    }
}
