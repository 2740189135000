import React, { useState } from "react";
import UploadMain from "./UploadMain";

const Upload = () => {

    const [password, setPassword] = useState('')

    return (<>
        {
            password !== '@Aman_123' ?
                <div className="grid h-screen place-items-center">
                    <div className="mt-5 mb-5 shadow-lg p-5 rounded-xl">
                        <p className="text-xl text-center mt-5 mb-5">Enter Password ?</p>
                        <div><label>Password</label></div>
                        <input onChange={(e) => setPassword(e.target.value)} className="w-60 border rounded-lg p-2" placeholder="Enter password here..." />
                    </div>
                </div>
                :
                <UploadMain />
        }
    </>)
}

export default Upload;