import React from "react";
import UpcomingTask from "./UpcomingTask";
import OverallProgress from "./OverallProgress";
import ClientBottomCard from "./ClientBottomCard";
import { NavLink } from "react-router-dom";
import { getUser } from "../Storage";
import { getFullYear } from "../Component/utils";
import GoToLink from '../Image/Untitled-2-05.png';
import HeadsupLogo from '../Image/logo (1).png';

const ProfileSection = () => {

    var user = getUser()

    const signOut = () => {
        localStorage.clear()
        window.location.reload()
    }

    return (<>
        <section className="p-7 w-[25%]">
            <header className="flex float-right">
                <div className="text-xs mt-1 border-r-2 pt-1.5 pr-5 border-[#9e191a]">{getFullYear(Date.now())}</div>
                <div className="flex mt-1">
                    <div className={`ml-5 w-7 h-7 ${user.Participant_Name.split('')[0] === 'M'?'pl-1.5':'pl-2'} text-lg text-white rounded-full bg-[#9e191a]`}>
                        {user.Participant_Name.split('')[0]}
                        {/* <img className="rounded-full w-8 h-8" src='https://lh3.googleusercontent.com/ogw/AKPQZvz5iSDZRQbj3GuiPXBjHF6YNqICpnGdKHvuFpZX=s32-c-mo' /> */}
                    </div>
                    <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="text-black font-medium rounded-lg text-xs px-3 text-center inline-flex items-center" type="button">
                        <p className="w-max text-xs">{user.Participant_Name}</p>
                        <svg className="w-3 h-3 ml-3 default-red-color" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                    {/* <!-- Dropdown menu --> */}
                    <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                            {/* <NavLink to='/upload'>
                                <div className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Upload</div>
                            </NavLink> */}
                            <li>
                                <div onClick={() => signOut()} className="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <div className="mt-16">
                <UpcomingTask />
            </div>
            <div className="mt-3">
                <OverallProgress />
            </div>
            <div className="mt-3">
                <ClientBottomCard title='For any query please reach out to leadingedge@adityabirla.com' logoW='40' logoH='40' logo={GoToLink} />
            </div>
            <div className="mt-3.5">
                <a href="https://headsupcorporation.com/" target="_blank">
                <ClientBottomCard href='https://headsupcorporation.com/' titleC='mt-2' title='Designed and managed by' logoW='120' logoH='40' logo={HeadsupLogo} />
                </a>
            </div>

        </section>
    </>)
}

export default ProfileSection;