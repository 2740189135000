import React from 'react';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import 'react-circular-progressbar/dist/styles.css';
import WelcomePageDetailsReducer from './Store/Reducer/WelcomePageDetailsReducer';

const rootReducer = combineReducers({
  WelcomePageDetailsReducer: WelcomePageDetailsReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Toaster />
      <App />
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);