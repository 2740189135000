import React from "react";
import Stepper from "../Component/Stepper";
import Logo from "../../src/Image/2D_GYANODAYA.jpg";
import { useDispatch } from "react-redux";
import { setWelcomePageDetails } from "../Store/Action/WelcomePageDetailsAction";

const MyLeadingEdgeJourneyOverview = () => {

    const dispatch = useDispatch()

    return (<>
        <section className="w-[29%]">
           <div className="h-full shadow-xl p-1">
           <header>
                <img className="cursor-pointer" onClick={()=>dispatch(setWelcomePageDetails(null))} width='100px' src={Logo} />
                <h6 style={{ marginLeft: '10px', borderBottom: '3px solid #9e191a' }} className="text-base pb-3 mt-1 default-red-color font-extrabold">My Leading Edge Journey Overview</h6>
            </header>
            <Stepper />
           </div>
        </section>

    </>)
}

export default MyLeadingEdgeJourneyOverview;