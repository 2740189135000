import React from "react";

const WelcomeScreen = (props) => {

    var height = window.innerHeight

    return (<>

        <div>
            <div className="absolute p-7">
                <header style={{ borderBottom: '1px solid #9e191a' }}>
                    <h4 style={{fontSize:'25px'}} className="text-black pb-3">{props.title}</h4>
                </header>
                <div className="pt-3">
                    <p style={{fontSize:'25px'}}>{props.status}</p>
                </div>
            </div>
            <img height={height} src={props.bg} />
        </div>

    </>)
}

export default WelcomeScreen;