import { WELCOME_PAGE_DETAILS } from "../Action/WelcomePageDetailsAction";

const initialState = {
    doc: null
}

const WelcomePageDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case WELCOME_PAGE_DETAILS:
            return ({ doc: action.value })
        default:
            return state;
    }
}

export default WelcomePageDetailsReducer;