import React, { useEffect, useState } from "react";
import { getUser } from "../Storage";
import { useDispatch, useSelector } from "react-redux";
import { setWelcomePageDetails } from "../Store/Action/WelcomePageDetailsAction";
import { decrypt } from "../Constants/Constants";

const Stepper = () => {

    const WelcomePageDetailsReducer = useSelector(state => state.WelcomePageDetailsReducer)

    const [title, setTitle] = useState('')
    const [decryptedUserDetails, setDecryptedUserDetails] = useState({});
    const dispatch = useDispatch()

    var getLocalUser = getUser()

    const decryptUserDetails = async () => {
        const keysToDecrypt = ['Role', 'Organization', 'KIP_Person_1_Org',
        'KIP_Person_1_Role', 'KIP_Person_2_Org', 'KIP_Person_2_Role', 'KIP_Person_3_Role',
        'KIP_Person_3_Org', 'KIP_Person_4_Role', 'KIP_Person_4_Org', 'KIP_Person_5_Role',
        'KIP_Person_5_Org', 'KIP_Person_6_Role', 'KIP_Person_6_Org', 'KYT_Leader_Details',
        'Shadow_Leader_Details', 'LC_Member_1_Role', 'LC_Member_1_Org', 'LC_Member_2_Role',
        'LC_Member_2_Org', 'LC_Member_3_Role', 'LC_Member_3_Org', 'LC_Member_4_Role',
        'LC_Member_4_Org', 'LC_Member_5_Role', 'LC_Member_5_Org', 'LC_Member_6_Role',
        'LC_Member_6_Org', 'LC_Member_7_Role', 'LC_Member_7_Org', 'LC_Member_8_Role',
        'LC_Member_8_Org'
    ];

        let decryptedData = {};
        for (const key of keysToDecrypt) {
            if (getLocalUser.hasOwnProperty(key)) {
                decryptedData[key] = await decrypt(getLocalUser[key]);
            }
        }
        return decryptedData;
    };


    useEffect(() => {
        decryptUserDetails().then(decryptedData => {
            // console.log("descrypted inside useEffect",decryptedData )
            setDecryptedUserDetails(decryptedData);
        });
    }, [WelcomePageDetailsReducer.doc]);


    const steps = [
        {
            label: '360',
            status: getLocalUser.Status_360,
        },
        {
            label: 'Core Modules at ISB',
            status: getLocalUser.Core_Modules_ISB_Status,
        },
        {
            label: 'Functional Module I',
            status: getLocalUser.Functional_Module_1_Status,
        },
        {
            label: 'Functional Module II',
            status: getLocalUser.Functional_Module_2_Status,
        },
        {
            label: 'Functional Module III',
            status: getLocalUser.Functional_Module_3_Status,
        },
        {
            label: 'Functional Module IV',
            status: getLocalUser.Functional_Module_4_Status,
        },
        {
            label: 'Core Modules at Gyanodaya',
            status: getLocalUser.Core_Modules_Gyanodaya_Status,
        },
        {
            label: 'Knowledge Integration Program (KIP)',
            status: getLocalUser.KIP_Overall_Status,
        },
        {
            label: 'Know Your Talent (KYT)',
            status: getLocalUser.KYT_Overall_Status,
        },
        {
            label: 'Shadow',
            status: getLocalUser.Shadow_Overall_Status,
        },
        {
            label: 'Leadership Circle (LC)',
            status: getLocalUser.LC_Overall_Status,
        },
    ];

    


    const onClick = (data) => {

        setTitle(data.label)

        if (data.label === 'Knowledge Integration Program (KIP)') {
            var json = {
                status:getLocalUser.KIP_Overall_Status,
                table: true,
                title: data.label,
                topic: getLocalUser.KIP_Topic,
                
                membersInThisGroup: [
                    {
                        name: getLocalUser.KIP_Person_1_Name,
                        role: decryptedUserDetails.KIP_Person_1_Role,
                        organisation: decryptedUserDetails.KIP_Person_1_Org
                    },
                    {
                        name: getLocalUser.KIP_Person_2_Name,
                        role: decryptedUserDetails.KIP_Person_2_Role,
                        organisation: decryptedUserDetails.KIP_Person_2_Org
                    },
                    {
                        name: getLocalUser.KIP_Person_3_Name,
                        role: decryptedUserDetails.KIP_Person_3_Role,
                        organisation: decryptedUserDetails.KIP_Person_3_Org
                    },
                    {
                        name: getLocalUser.KIP_Person_4_Name,
                        role: decryptedUserDetails.KIP_Person_4_Role,
                        organisation: decryptedUserDetails.KIP_Person_4_Org
                    },
                    {
                        name: getLocalUser.KIP_Person_5_Name,
                        role: decryptedUserDetails.KIP_Person_5_Role,
                        organisation: decryptedUserDetails.KIP_Person_5_Org
                    },
                    {
                        name: getLocalUser.KIP_Person_6_Name,
                        role: decryptedUserDetails.KIP_Person_6_Role,
                        organisation: decryptedUserDetails.KIP_Person_6_Org
                    },
                ],
                footer: [
                    {
                        date: getLocalUser.KIP_1_Date,
                        status: getLocalUser.KIP_1_Status,
                        title: 'KIP Introductory Meet'
                    },
                    {
                        date: getLocalUser.KIP_2_Date,
                        status: getLocalUser.KIP_2,
                        title: 'KIP MEET 2'
                    },
                    {
                        date: getLocalUser.KIP_3_Date,
                        status: getLocalUser.KIP_3,
                        title: 'KIP Dry Run'
                    },
                    {
                        date: getLocalUser.KIP_4_Date,
                        status: getLocalUser.KIP_4,
                        title: 'KIP Final Event'
                    },
                    {
                        date: getLocalUser.KIP_5_Date,
                        status: getLocalUser.KIP_5,
                        title: 'KIP Feedback Shared'
                    },

                ]
            }
            // console.log(json);
            dispatch(setWelcomePageDetails(json))
        }
        else if (data.label === 'Leadership Circle (LC)') {
            var json = {
                status:getLocalUser.LC_Overall_Status,
                table: true,
                title: data.label,
                membersInThisGroup: [
                    {
                        name: getLocalUser.LC_Member_1_Name,
                        role: decryptedUserDetails.LC_Member_1_Org,
                        organisation: decryptedUserDetails.LC_Member_1_Role
                    },
                    {
                        name: getLocalUser.LC_Member_2_Name,
                        role: decryptedUserDetails.LC_Member_2_Role,
                        organisation: decryptedUserDetails.LC_Member_2_Org
                    },
                    {
                        name: getLocalUser.LC_Member_3_Name,
                        role: decryptedUserDetails.LC_Member_3_Role,
                        organisation: decryptedUserDetails.LC_Member_3_Org
                    },
                    {
                        name: getLocalUser.LC_Member_4_Name,
                        role: decryptedUserDetails.LC_Member_4_Role,
                        organisation: decryptedUserDetails.LC_Member_4_Org
                    },
                    {
                        name: getLocalUser.LC_Member_5_Name,
                        role: decryptedUserDetails.LC_Member_5_Role,
                        organisation: decryptedUserDetails.LC_Member_5_Org
                    },
                    {
                        name: getLocalUser.LC_Member_6_Name,
                        role: decryptedUserDetails.LC_Member_6_Role,
                        organisation: decryptedUserDetails.LC_Member_6_Org
                    },
                    {
                        name: getLocalUser.LC_Member_7_Name,
                        role: decryptedUserDetails.LC_Member_7_Role,
                        organisation: decryptedUserDetails.LC_Member_7_Org
                    },
                    {
                        name: getLocalUser.LC_Member_8_Name,
                        role: decryptedUserDetails.LC_Member_8_Role,
                        organisation: decryptedUserDetails.LC_Member_8_Org
                    },
                ],
                footer: [
                    {
                        date: getLocalUser.LC_1_Date,
                        status: getLocalUser.LC_1,
                        title: 'LC MEET 1'
                    },
                    {
                        date: getLocalUser.LC_2_Date,
                        status: getLocalUser.LC_2,
                        title: 'LC MEET 2'
                    },
                    {
                        date: getLocalUser.LC_3_Date,
                        status: getLocalUser.LC_3,
                        title: 'LC MEET 3'
                    },
                    {
                        date: getLocalUser.LC_4_Date,
                        status: getLocalUser.LC_4,
                        title: 'LC MEET 4'
                    },
                    {
                        date: getLocalUser.LC_5_Date,
                        status: getLocalUser.LC_5,
                        title: 'LC Feedback Shared'
                    },

                ]
            }
            // console.log(json);
            dispatch(setWelcomePageDetails(json))
        }
        else if (data.label === 'Shadow') {
            var json = {
                status:getLocalUser.Shadow_Overall_Status,
                table: false,
                title: data.label,
                LeaderName: getLocalUser.Shadow_Leader_Name,
                LeaderDetails: decryptedUserDetails.Shadow_Leader_Details,
                OverallStatus: getLocalUser.Shadow_Overall_Status,
                footer: [
                    {
                        date: getLocalUser.Shadow_Date_1,
                        status: getLocalUser.Shadow_1,
                        title: 'Shadow Invite Received'
                    },
                    {
                        date: getLocalUser.Shadow_Date_2,
                        status: getLocalUser.Shadow_2,
                        title: 'Shadow To Be Scheduled'
                    },
                    {
                        date: getLocalUser.Shadow_Date_3,
                        status: getLocalUser.Shadow_3,
                        title: 'Shadow Final Event'
                    },
                    {
                        date: getLocalUser.Shadow_Date_4,
                        status: getLocalUser.Shadow_4,
                        title: 'Shadow Feedback Shared'
                    },

                ]
            }
            // console.log(json);
            dispatch(setWelcomePageDetails(json))
        }
        else if (data.label === 'Know Your Talent (KYT)') {
            var json = {
                status:getLocalUser.KYT_Overall_Status,
                table: false,
                title: data.label,
                LeaderName: getLocalUser.KYT_Leader_Name,
                LeaderDetails: decryptedUserDetails.KYT_Leader_Details,
                OverallStatus: getLocalUser.KYT_Overall_Status,
                footer: [
                    {
                        date: getLocalUser.KYT_Date_1,
                        status: getLocalUser.KYT_1,
                        title: 'KYT Invite Received'
                    },
                    {
                        date: getLocalUser.KYT_Date_2,
                        status: getLocalUser.KYT_2,
                        title: 'KYT To Be Scheduled'
                    },
                    {
                        date: getLocalUser.KYT_Date_3,
                        status: getLocalUser.KYT_3,
                        title: 'KYT Final Event'
                    },
                    {
                        date: getLocalUser.KYT_Date_4,
                        status: getLocalUser.KYT_4,
                        title: 'KYT Feedback Shared'
                    },
                ]
            }
            // console.log(json);
            dispatch(setWelcomePageDetails(json))
        }

        else if(data.label === '360'){
            var json = {
                title: data.label,
                status: getLocalUser.Status_360
            }
            dispatch(setWelcomePageDetails(json))
        }
        else if(data.label === 'Core Modules at ISB'){
            var json = {
                title: data.label,
                status: getLocalUser.Core_Modules_ISB_Status
            }
            dispatch(setWelcomePageDetails(json))
        }
        else if(data.label === 'Functional Module I'){
            var json = {
                title: data.label,
                status: getLocalUser.Functional_Module_1_Status
            }
            dispatch(setWelcomePageDetails(json))
        }
        else if(data.label === 'Functional Module II'){
            var json = {
                title: data.label,
                status: getLocalUser.Functional_Module_2_Status
            }
            dispatch(setWelcomePageDetails(json))
        }
        else if(data.label === 'Functional Module III'){
            var json = {
                title: data.label,
                status: getLocalUser.Functional_Module_3_Status
            }
            dispatch(setWelcomePageDetails(json))
        }
        else if (data.label === 'Functional Module IV') {
            var json = {
                title: data.label,
                status: getLocalUser.Functional_Module_4_Status
            }
            dispatch(setWelcomePageDetails(json))
        }
        else if (data.label === 'Core Modules at Gyanodaya') {
            var json = {
                title: data.label,
                status: getLocalUser.Core_Modules_Gyanodaya_Status
            }
            dispatch(setWelcomePageDetails(json))
        }
        else {
            dispatch(setWelcomePageDetails(null))
        }
    }

    return (<>

        <div className="flex justify-between pt-1.5">
            <h3 className={`text-base font-bold leading-tight ml-2.5`}>Steps</h3>
            <p className={`ttext-base font-bold leading-tight ml-2.5 w-20`}>Status</p>
        </div>

        <ol className="relative text-black border-l border-black ml-5 mt-5">
            {
                steps.map((ele, i) => {
                    return (
                        <div className={`rounded-r-xl ${title === ele.label && ele.status === 'Completed' ? 'bg-[#CAE0CA] ' : title === ele.label && ele.status === 'Yet to start' ? 'bg-[#EAC9BA]' : title === ele.label ? 'bg-[#C3D8EA]' : ''}`}>
                            <li className="mb-10 ml-6 cursor-pointer" onClick={() => onClick(ele)}>
                                <span className={`text-black absolute flex items-center justify-center w-6 rounded-full -left-3 ${title === ele.label && ele.status === 'Completed' ? 'bg-[#CAE0CA]' : title === ele.label && ele.status === 'Yet to start' ? 'bg-[#EAC9BA]' : title === ele.label ? 'bg-[#C3D8EA]' : 'bg-[#F2B9C6]'}`}>{i + 1}</span>
                                <div className="ml-5 mr-5 flex justify-between" style={{ marginTop: '-7px' }}>
                                    <h3 className={`leading-tight text-xs font-bold pt-1 h-6`}>{ele.label}</h3>
                                    <p className={`text-xs px-2 py-0.5 rounded-3xl font-bold text-center pt-1 ${ele.status === 'Completed' ? 'bg-[#CAE0CA]' : ele.status === 'Yet to start' ? 'bg-[#EAC9BA]' : 'bg-[#C3D8EA]'}`}>{ele.status}</p>
                                </div>
                            </li>
                        </div>
                    )
                })
            }

        </ol>

    </>)
}

export default Stepper;